import React from "react";
import { Link } from "gatsby";
import "../styles/header.css";


function Header() {
  return (
    <>
      <Link className = "header-title font-NexaRushSlabBlackShadow" to="/">🄿🄷🄾🄽🄴🅃🄸🄲🅂 🅃🅁🄰🄽🅂🄲🅁🄸🄿🅃🄸🄾🄽</Link>
      <h3 className = "color-main text-center font-futura">Converting words to phonetics symbols. Supported languages: Vietnamese, English</h3>
    </>
  );
}
export default Header;
